import { LOGIN_USER, UPDATE_USER } from '../actions'

function user (state = {}, action) {
  console.log('Reducer Action: ',action);
  switch (action.type) {
    case LOGIN_USER :
      return {
        ...state,
        user: action.user
      }
    case UPDATE_USER :
      return {
        ...state,
        user: action.userData
      }
    default :
      return state
  }
}

export default user
