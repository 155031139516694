export const RECEIVE_ENTRIES = 'RECEIVE_ENTRIES'
export const ADD_ENTRY = 'ADD_ENTRY'
export const GET_USER = 'GET_USER'

export const ALERT_SUCCESS = 'ALERT_SUCCESS'
export const ALERT_ERROR = 'ALERT_ERROR'
export const ALERT_CLEAR = 'ALERT_CLEAR'

export const REGISTER_REQUEST = 'USERS_REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'USERS_REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'USERS_REGISTER_FAILURE'

export const LOGIN_REQUEST = 'USERS_LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE'

export const LOGOUT = 'USERS_LOGOUT'

export const GETALL_REQUEST = 'USERS_GETALL_REQUEST'
export const GETALL_SUCCESS = 'USERS_GETALL_SUCCESS'
export const GETALL_FAILURE = 'USERS_GETALL_FAILURE'

export const DELETE_REQUEST = 'USERS_DELETE_REQUEST'
export const DELETE_SUCCESS = 'USERS_DELETE_SUCCESS'
export const DELETE_FAILURE = 'USERS_DELETE_FAILURE'


export const LOGIN_USER = 'LOGIN_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const GET_CLIENTS = 'GET_CLIENTS'

export const UPDATE_NOTES = 'UPDATE_NOTES'


export function success(message) {
  return { type: ALERT_SUCCESS, message };
}
export function error(message) {
  return { type: ALERT_ERROR, message };
}
export function clear() {
  return { type: ALERT_CLEAR };
}

export function receiveEntries (entries) {
  return { type: RECEIVE_ENTRIES, entries }
}
export function addEntry (entry) {
  return { type: ADD_ENTRY, entry }
}

export function updateNotes (note) {
  return { type: UPDATE_NOTES, note }
}

export function loginUser (user) {
  return { type: LOGIN_USER, user }
}
export function updateUser (user) {
  return { type: UPDATE_USER, user }
}

export function getClients (clients) {
  return { type: GET_CLIENTS, clients }
}
