import React from 'react'
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';

export default function OsStepper ({ unit, value, onIncrement, onDecrement }) {
  return (
    <div style={styles.stepContainer}>
      <div style={styles.metricIncrement}>
        <a style={styles.androidBtn} onClick={onDecrement}>
          <AiFillMinusCircle size={30} color='#FFFFFF' />
        </a>
        <a style={styles.androidBtn} onClick={onIncrement}>
          <AiFillPlusCircle size={30} color='#FFFFFF' />
        </a>
      </div>
      <div style={styles.metricCounter}>
        <div style={{fontSize: 18, textAlign: 'center', color: '#6d6d6d'}}>{value}</div>
        <div style={{fontSize: 12, textAlign: 'center', color: '#9e9e9e'}}>{unit}</div>
      </div>
    </div>
  )
}

const styles = {
  stepContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  androidBtn: {
    margin: 5,
    width:50,
    height:50,
    backgroundColor: '#1e7bbd',
    padding: 10,
    borderRadius: 25,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  metricIncrement: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft:20,
    paddingRight:20,
  },
  metricCounter: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft:20,
    paddingRight:20,
  },
}
