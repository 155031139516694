import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate  } from 'react-router-dom';

import LegoGuy from '../assets/lego_guy.png';
import { apiGetClients } from '../utils/api';
import { font_type } from '../utils/helpers';

const moderateScale = (size) => size + 'px';

const mapStateToProps = state => ({
  ...state,
  user: state.user.user
});

class DashboardPage extends Component {

  constructor(props) {
 		super(props);
    const clientList = [];
 		this.state = {
      profileDetailsSource: clientList,
      clientMessage: '',
      loadingAnimation: false,
      page: 1,
      redirectTo: '',
      selectedClientObject: {},
    };
 	}

  componentDidMount() {
    this.fetchClientList();
	}

  fetchClientList() {
    //console.log('Fetching Client List Triggered - Page: '+this.state.page);
    const { user } = this.props
    this.setState({loadingAnimation: true});
    apiGetClients(user.session,this.state.page)
    .then((clientListResult) => {
      if(clientListResult.error == 1) {
        //console.log('Client List ERROR: ', clientListResult.message);
        //console.log('-'+clientListResult.message+'-');
        if(clientListResult.message == 'Could not find user session, try logging in again.') {
          //this.props.navigation.navigate('LoginPage'); //Navigation - Go back to log-in if we can't find their session.
        }
      } else {
        //console.log('Client List SUCCESS : ', clientListResult.data);
        this.setState({
          profileDetailsSource: clientListResult.data,
          clientMessage: '',
          page: (this.state.page + 1)
        });
      }
      this.setState({loadingAnimation: false});
    })
    .catch((error) => {console.error(error);this.setState({loadingAnimation: false});});
  }

  goToPage = (rowData) => {
    this.setState({
      selectedClientObject: {
        clientId: rowData.id,
        clientName: rowData.clientcode,
        clientAddress: rowData.address,
        clientCode: rowData.clientcode,
        clientData: JSON.parse(rowData.dataTemplate)
      },
      redirectTo: 'client'
    })
  }

  _renderProfileDetailRow(rowData) {
    return (
      <a key={rowData.key} onClick={() => {this.goToPage(rowData)}}>
        <div style={styles.main_view_row}>
          <div style={styles.row}>
            <div style={styles.details}>
              <span style={styles.name}>{rowData.clientcode}</span>
            </div>
            <div style={styles.details}>
              {rowData.status == 1 ?
                <span style={styles.address}>Active</span>
              : null }
            </div>
          </div>
        </div>
      </a>
    );
  }

  openMenu = () => {
    //this.props.navigation.toggleDrawer();
  }

  render() {

    const { user } = this.props
    //console.log('Render user ', user);
    //console.log('Render this ', this);

    if(this.state.redirectTo !== '') {
      return (
        <Navigate to={this.state.redirectTo} state={{selectedClientObject: this.state.selectedClientObject}} />
      );
    }

    return (
      <div style={styles.main}>

        <div style={styles.content_view}>

          <div style={styles.profile_view}>
            <img style={styles.profile_img} src={LegoGuy} />
            <div style={styles.name_txt}>{user.name}</div>
          </div>

          <div style={styles.overlay_content_bg}>

            <div style={styles.card_bg}>

              <div style={styles.about_description_txt}>
                Select a client below to enter notes.
              </div>

              {this.state.loadingAnimation ?
                <div style={styles.container}>
                  LOADING...
                </div>
                :
                <div style={styles.detail_profile_bg}>
                  {this.state.clientMessage
                    ? <span style={styles.error_txt}>{this.state.clientMessage}</span>
                    : <>
                        {this.state.profileDetailsSource && this.state.profileDetailsSource.length > 0 &&
                          <>
                            {this.state.profileDetailsSource.map((item, index) => this._renderProfileDetailRow(item, index))}
                          </>
                        }
                      </>
                  }
                </div>
              }

              <span style={styles.about_version_txt}>V3.0.7</span>

            </div>

          </div>

        </div>

      </div>
    )
  }
}

const styles = {
  main: {
    backgroundColor: '#1e7bbd',
  },
  setting_icon: {
  	height:1,
  	width:1,
  	resizeMode: 'contain'
  },
  profile_img: {
    width: 60,
    height: 60,
    borderRadius: '50%',
    borderWidth: 3,
    borderColor: 'white',
    alignSelf: 'center',
    marginRight: 15,
  },
  name_txt: {
    color: 'white',
    fontSize: moderateScale(18),
    textAlign: 'center',
    fontFamily: font_type.FontBold,
  },
  error_txt: {
    color: "#1e79b9",
    fontSize: moderateScale(14),
    fontFamily: font_type.FontBold,
    flexDirection: 'row',
    marginTop: 15,
    paddingTop: 15,
    alignItems: 'center',
    borderRadius: 1,
    borderWidth: 0,
    borderColor: '#EEEEEE',
    borderStyle: 'dashed',
    borderTopWidth: 1,
    textAlign: 'center'
  },
  detail_profile_bg: {
    flexDirection: 'row',
    borderRadius: 10,
    padding: 3,
    marginBottom: 10,
  },
  about_description_txt: {
    color: "#6d6d6d",
    fontSize: moderateScale(12),
    marginBottom: 15,
    marginTop: 5,
    textAlign: 'center'
  },
  about_version_txt: {
    color: "#6d6d6d",
    fontSize: moderateScale(10),
    marginLeft: 3,
    marginRight: 3,
    marginBottom: 1,
    marginTop: 1,
    textAlign: 'center'
  },
  divider_horizontal: {
    backgroundColor: "#ebebeb",
    width: '80%',
    alignSelf: 'center',
    marginBottom: 1,
    marginTop: 2
  },
  connection_user_img: {
    width: 32,
    height: 32,
    borderRadius: 16,
    resizeMode: 'cover'
  },
  photod_header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 3
  },
  gallery_img: {
    width: 28,
    height: 18,
    resizeMode: 'cover'
  },
  rowname_txt: {
    color: "#363636",
    fontSize: moderateScale(15),
    fontFamily: font_type.FontBold
  },
  profile_view:{
    display: 'flex',
    backgroundColor: "#1e7bbd",
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  overlay_content_bg: {
    padding: 20,
    alignSelf: 'center',
  },
  card_bg: {
    backgroundColor: '#FFF',
    display: 'flex',
    flex:1,
    flexDirection: 'column',
    alignSelf: 'center',
    borderRadius: 10,
    padding: 30,
  },
  back_bottom_view:{
    backgroundColor: "#fcfcfc"
  },
  content_view:{
    backgroundColor: '#EEE',
    justifyContent:'flex-start',
    alignItems:'center',
  },
  main_view_row:{
    flexDirection: 'row',
    marginTop: 0,
    padding: 10,
    alignItems: 'center',
    borderRadius: 1,
    borderWidth: 0,
    borderColor: '#EEEEEE',
    borderStyle: 'dashed',
    borderTopWidth: 1,
    cursor: 'pointer',
  },
  main_img_row:{
    width:'10%',
    borderRadius: '100%'
  },
  row:{
    flexDirection: 'column'
  },
  details:{
    flexDirection:'row',
    alignItems:'center'
  },
  name:{
    color: "#6d6d6d",
    fontSize: moderateScale(16)
  },
  address:{
    color: "#9e9e9e",
    fontSize: moderateScale(12),
    marginLeft: 2
  },
  desc_row:{
    color: "#6f6f6f",
    fontSize: moderateScale(15),
    marginTop: 3,
    textAlign: 'left'
  },
  container: {
    flex: 1,
    justifyContent: 'center'
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10
  }
};

export default connect(mapStateToProps)(DashboardPage)
