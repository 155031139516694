import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

class Error404 extends React.Component {

  constructor() {
    super();
    this.state = {
      
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="auth-page">
        <div className="container-fluid page">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-xs-12 fullheight">
              <div className="error404">
                <h1>Sorry! Page not found.</h1>
                <p>Error: 404</p>
                <Link to="/">
                  <button className="btn btn-lg btn-primary text-center">
                    Return Home
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Error404);
