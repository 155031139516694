import { UPDATE_NOTES } from '../actions'

function note (state = {}, action) {
  //console.log('Reducer Action: ',action);
  switch (action.type) {
    case UPDATE_NOTES :
      return {
        ...state,
        note: action.note
      }
    default :
      return state
  }
}

export default note
