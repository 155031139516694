import React, { Component } from 'react';
import { connect } from 'react-redux';
import OsSlider from './OsSlider';
import OsStepper from './OsStepper';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useParams, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { AiOutlinePlus, AiOutlineDelete, AiOutlineCheckCircle, AiOutlineCloseCircle, AiFillCaretUp, AiFillCaretDown, AiOutlineLoading } from 'react-icons/ai';

import { timeToStringSimple, timeToStringTimeonly } from '../utils/helpers';

import { font_type } from '../utils/helpers';

const moderateScale = (size) => size + 'px';

const withRouter = WrappedComponent => props => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  return (
      <WrappedComponent
          {...props}
          params={params}
          navigate={navigate}
          location={location}
      />
  );
};


class ClientDataCollection extends Component {

  constructor(props) {
 		super(props);

    
    
    this.contentRef = React.createRef();

 		this.state = {
      sessionDataSource: props.location?.state?.selectedClientObject?.clientData,
      modalVisible: false,
      modalType: 'count',
      modalCountType: '',
      modalTitle: '',
      modalValue: 0,
      modalSection: 0,
      modalId: 0,
      modalData: null,
      modalChecked: undefined,
      modalSelected: undefined,
      loadingAnimation: false,
      accordionExpanded: 0,
      tooltipVisible: false,
      target: null,
      parent: this.contentRef,
      toolTipVisible: false,
      redirectTo: '',
      selectedClientObject: props.location?.state?.selectedClientObject,
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.CANCEL_INDEX = 0;
    this.DESTRUCTIVE_INDEX = 0;

 	}

  shouldComponentUpdate(nextProps, nextState) { //Deal with updating the progress percentages when the state is updated from anywhere.
    let measurementsTotal = 0;
    let measurementsDone = 0;
    let percentageDone = 0;
    nextState.sessionDataSource.map((section,sectionkey) => {
      measurementsTotal = 0;measurementsDone = 0;percentageDone = 0;
      section.measurements.map((measurementval,measurementkey) => {
        if(measurementval.required && section.title == 'Goals') {
          measurementsTotal++; //It's required, so add to total
          //console.log(measurementval);
          if(measurementval.data) {
            if(measurementval.data.length) { //Data has been entered.
              measurementsDone++;
            }
          }
        }
      })
      //console.log(measurementsDone);console.log(measurementsTotal);
      if(measurementsDone == 0 && measurementsTotal > 0) {
        percentageDone = 0;
      } else if(measurementsDone > 0 && measurementsTotal > 0 && measurementsDone <= measurementsTotal) {
        percentageDone = Math.ceil((measurementsDone/measurementsTotal) * 100);
      } else {
        percentageDone = 100;
      }
      nextState.sessionDataSource[sectionkey].completionPercent = percentageDone;
      nextState.sessionDataSource[sectionkey].completionText = percentageDone+'% Complete';
    })
    return true;
  }

  _renderHeader(item, expanded) {
    return (
      <div style={styles.main_view_row}>
        <div style={styles.left}>
          <AiOutlineCheckCircle size={25} color={item.completionPercent == 100 ? '#42c502' : '#b6b6b6'} />
        </div>
        <div style={styles.dataRow}>
          <div style={styles.details}>
            <div style={styles.name}>{item.title}</div>
          </div>
          <div style={styles.details}>
            <div style={styles.completionText}>{item.completionText}</div>
          </div>
          <div style={styles.completionPercent}>
            <div style={{
              height: 8,
              flex:item.completionPercent,
              backgroundColor: (item.completionPercent == 100 ? '#42c502' : '#07c6fd'),
              borderRadius: 4
            }}></div>
            <div style={{
              backgroundColor: 'rgba(0,0,0,0)',
              height: 8,flex: 90,
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              flex:(100-item.completionPercent)
            }}></div>
          </div>
        </div>
        <div style={styles.right}>
          {expanded
            ? <AiFillCaretUp size={20} color='#b6b6b6' style={{textAlign: 'right'}} />
            : <AiFillCaretDown size={20} color='#b6b6b6' style={{textAlign: 'right'}} />
          }
        </div>
      </div>
    );
  }

  handleAddPlus(measurement,id,section) {

    //console.log(measurement);
    let modalChecked = [];modalChecked.push(true);
    let modalSelected = [];modalSelected.push(measurement.startingVal.levelsLabels[0]);
    this.setState({
      modalTitle: measurement.title,
      modalType: measurement.type,
      modalCountType: measurement.collectionType,
      modalValue: measurement.startingVal,
      modalSection: section,
      modalId: id,
      modalData: measurement.data,
      modalChecked: modalChecked,
      modalSelected: modalSelected
    }, () => {
      this.handleAddSave(measurement,id,section);
    });

  }

  handleAddMinus(measurement,id,section) {

    //console.log(measurement);
    let modalChecked = [];modalChecked.push(true);
    let modalSelected = [];modalSelected.push(measurement.startingVal.levelsLabels[1]);
    this.setState({
      modalTitle: measurement.title,
      modalType: measurement.type,
      modalCountType: measurement.collectionType,
      modalValue: measurement.startingVal,
      modalSection: section,
      modalId: id,
      modalData: measurement.data,
      modalChecked: modalChecked,
      modalSelected: modalSelected
    }, () => {
      this.handleAddSave(measurement,id,section);
    });

  }

  handleAddSave(measurement,id,section) {

    if(measurement.title == this.state.modalTitle) { //Check if we are hitting save on the currently active row.
      this.setModalSave();
    }

  }

  handleAdd(measurement,id,section) {
    this.setModalVisible(true);
    let modalChecked = null;
    let modalSelected = null;
    if(typeof measurement.startingVal === 'object') { //It's an object, so meant for the multiselect
      modalChecked = [];
      modalSelected = [];
      measurement.startingVal.steps.map(function(object, i){
        modalChecked.push(false);
        modalSelected.push('');
      });
      let thisCount = measurement.startingVal.levelsLabels.length;
      measurement.startingVal.levelsLabels.push('Clear Value');
      measurement.startingVal.levelsLabels.push('Cancel');
      this.DESTRUCTIVE_INDEX = thisCount;
      this.CANCEL_INDEX = thisCount + 1;
    }
    this.setState({
      modalTitle: measurement.title,
      modalType: measurement.type,
      modalCountType: measurement.collectionType,
      modalValue: measurement.startingVal,
      modalSection: section,
      modalId: id,
      modalData: measurement.data,
      modalChecked: modalChecked,
      modalSelected: modalSelected
    });
  }

  increment = (value) => {
    const count = value + 1;
    this.setState({
      modalValue: count > 999 ? 10 : count,
    });
  }

  decrement = (value) => {
    const count = value - 1;
    this.setState({
      modalValue: count < 1 ? 0 : count,
    });
  }

  setModalVisible(visible) {
    this.setState({modalVisible: visible});
  }

  deleteDataItem(section,item,key) {
    //console.log(section,item,key);
    confirmAlert({title: 'CONFIRM', message: 'Are you sure you want to delete this data? There is no reverse.',
      buttons: [
        {label: 'No, Cancel', onClick: () => {}},
        {label: 'Yes, Delete Data', onClick: () => {
          let newSessionData = this.state.sessionDataSource[section].measurements[item].data.filter(function(dataitem,datakey) {
            return datakey !== key
          });
          let newSessionDataSource = this.state.sessionDataSource;
          newSessionDataSource[section].measurements[item].data = newSessionData;
          let newAverage = this.calcAverages(newSessionDataSource);
          newSessionDataSource[this.state.modalSection].measurements[this.state.modalId].average = newAverage;
          this.setState({
            sessionDataSource: newSessionDataSource,
            modalData: newSessionData
          });
        }},
      ]
    });
  }

  calcAverages(newSessionDataSource) {

    let newTotal = 0;
    let newCount = 0;
    let newAverage = 0;
    let nominimum = 0;

    if(this.state.modalType == 'slider' || this.state.modalType == 'count') {
      newSessionDataSource[this.state.modalSection].measurements[this.state.modalId].data.map((dataval,datakey) => {
        dataval.value = parseInt(dataval.value);
        newCount++;newTotal += dataval.value;
      });
      if(newCount > 0 && newTotal > 0) {newAverage = (newTotal/newCount).toFixed(1);}
    }
    if(this.state.modalType == 'duration') {
      newSessionDataSource[this.state.modalSection].measurements[this.state.modalId].data.map((dataval,datakey) => {newTotal += dataval.value;});
      let minutesSinceStart = (Date.now() - this.props.location?.state?.selectedClientObject?.clientDate)/1000/60/60; //Changed to per hour
      if(newTotal > 0) {newAverage = (newTotal/minutesSinceStart).toFixed(1);}
    }
    if(this.state.modalType == 'freetext') {
      newAverage = 0;
    }
    if(this.state.modalType == 'multiselect') {
      newSessionDataSource[this.state.modalSection].measurements[this.state.modalId].data.map((dataval,datakey) => {
        dataval.value.steps.map((stepsval,stepskey) => {
          //dataval.value.levels = ["P", "V", "I", "P", "P", "P", "P", "P", "P", "P", "P", "P", "P"]
          if(stepsval) {
            newCount++;
            let stepValue = 0;
            newSessionDataSource[this.state.modalSection].measurements[this.state.modalId].startingVal.levelsLabels.map((levelval,levelkey) => {
              if(levelval == dataval.value.levels[stepskey]) {
                stepValue = newSessionDataSource[this.state.modalSection].measurements[this.state.modalId].startingVal.levelsValues[levelkey];
              }
            });
            newTotal = newTotal + parseInt(stepValue);
          }
        });
        nominimum = 2;
        if(typeof newSessionDataSource[this.state.modalSection].measurements[this.state.modalId].nominimum !== 'undefined' && newSessionDataSource[this.state.modalSection].measurements[this.state.modalId].nominimum) {
          nominimum = 1;
        }
      });
      if(nominimum == 2 && newCount < 4) {
        newAverage = 0;
      } else {
        if(newCount > 0 && newTotal > 0) {
          newAverage = (newTotal/newCount).toFixed(2);
          newAverage = newAverage*100;
        }
      }
    }

    return newAverage;

  }

  setModalSave() {
    let addThis = 1;
    let newSessionData = {loggedBy: this.props.user.name,timeStamp: Date.now(),value: undefined}
    if(this.state.modalType == 'multiselect') {
      newSessionData.value = {
        levels: this.state.modalSelected,
        steps: this.state.modalChecked
      }
    } else {
      newSessionData.value = this.state.modalValue;
      if(this.state.modalValue == 0) {addThis = 0;} //Value was not above 0, so no need to add this.
    }
    let newSessionDataSource = this.state.sessionDataSource;
    newSessionDataSource[this.state.modalSection].measurements[this.state.modalId].data.push(newSessionData);

    let newAverage = this.calcAverages(newSessionDataSource);

    newSessionDataSource[this.state.modalSection].measurements[this.state.modalId].average = newAverage;
    if(addThis) {
      this.setState({
        modalVisible: false,
        sessionDataSource: newSessionDataSource
      }, () => {
        this.finalDataSave(false) //Save the data, but don't redirect, and only do this after the state has been changed.
      });
    }
  }
  
  displayToolTip(target,parent,text) {
    //console.log(parent);
    //console.log(target);
    //console.log(text);
    // this.setState({
    //   tooltipVisible: true,
    //   target: target,
    // });
    // RNTooltips.Show(
    // target,
    // parent,
    // {
    //   text: text
    // }
    // );
  }

  displayDate(timestamp) {
    timestamp = (typeof timestamp !== 'undefined') ?  timestamp : Date.now();
    const strTime = timeToStringSimple(timestamp)
    return strTime;
  }

  displayTime(timestamp) {
    timestamp = (typeof timestamp !== 'undefined') ?  timestamp : Date.now();
    const strTime = timeToStringTimeonly(timestamp)
    return strTime;
  }

  onModalCheckboxChange(value, key) {
    let modalChecked = this.state.modalChecked;
    modalChecked[key] = value;
    this.setState({
      modalChecked: modalChecked
    });
  }

  onModalSelectChange(value, key) {
    //console.log(value);console.log(key);
    if(value == 'Cancel') {
      return false;
    }
    if(value == 'Clear Value') {
      value = '';
    }
    let modalSelected = this.state.modalSelected;
    modalSelected[key] = value;
    this.setState({
      modalSelected: modalSelected
    });
  }

  _reCalcAveragesAsync = () => new Promise((resolve) => {
    let currentData = this.state.sessionDataSource;
    currentData[1].measurements.map((measurement,measurementkey) => {
      //console.log(measurement);
      let newTotal = 0;let newCount = 0;let newAverage = 0;let nominimum = 0;
      if(measurement.type == 'slider' || measurement.type == 'count') {
        measurement.data.map((dataval,datakey) => {
          dataval.value = parseInt(dataval.value);newCount++;newTotal += dataval.value;
        });
        if(newCount > 0 && newTotal > 0) {newAverage = parseFloat((newTotal/newCount).toFixed(1));}
      }
      if(measurement.type == 'duration') {
        measurement.data.map((dataval,datakey) => {newTotal += dataval.value;});
        let minutesSinceStart = (Date.now() - this.props.location?.state?.selectedClientObject?.clientDate)/1000/60/60; //Changed to per hour
        if(newTotal > 0) {newAverage = parseFloat((newTotal/minutesSinceStart).toFixed(1));}
      }
      if(measurement.type == 'freetext') {
        newAverage = 0;
      }
      if(measurement.type == 'multiselect') {
        measurement.data.map((dataval,datakey) => {
          dataval.value.steps.map((stepsval,stepskey) => {
            if(stepsval) {
              newCount++;let stepValue = 0;
              measurement.startingVal.levelsLabels.map((levelval,levelkey) => {
                if(levelval == dataval.value.levels[stepskey]) {stepValue = measurement.startingVal.levelsValues[levelkey];}
              });
              newTotal = newTotal + parseInt(stepValue);
            }
          });
          nominimum = 2;if(typeof measurement.nominimum !== 'undefined' && measurement.nominimum) {nominimum = 1;}
        });
        if(nominimum == 2 && newCount < 4) {
          newAverage = 0;
        } else {
          if(newCount > 0 && newTotal > 0) {newAverage = parseFloat((newTotal/newCount).toFixed(2));newAverage = newAverage*100;}
        }
      }
      if(newAverage) {
        newAverage = parseFloat(newAverage.toFixed(1));
        currentData[1].measurements[measurementkey].average = newAverage;
      }
      //Set the new average.
    });
    this.setState({
      sessionDataSource: currentData,
    }, () => {resolve(1)});
  })

  async finalDataSave(goBack) {
    const averages = await this._reCalcAveragesAsync();
    //console.log('averages ',averages);
    if(averages) {
      const { location } = this.props
      //console.log(1,this.state.modalTitle);
      this.setState({loadingAnimation: true, modalTitle: ''});
      //console.log(this.state.sessionDataSource);
      //return false;

      let clientString = 'client'+location?.state?.selectedClientObject?.clientId;
      const existingSessionData = localStorage.getItem('KidsLife:'+clientString);
      if(existingSessionData) {
        const dataJson = JSON.parse(existingSessionData);
        if(dataJson){
          //Local storage has an existing session open for this client.

          const newSessionData = {
            clientData:this.state.sessionDataSource
          }
          const sessionData = {...dataJson.sessionData, ...newSessionData};
          localStorage.setItem('KidsLife:'+clientString, JSON.stringify({sessionData: sessionData}));
          if(goBack) {
            this.setState({
              redirectTo: '/client'
            })
          }
        } else {
          confirmAlert({title: 'ERROR', message: 'Could not find session data, go back to previous page.',
            buttons: [{label: 'Cancel', onClick: () => console.log('Cancel')}]
          });
        }
      }
      this.setState({loadingAnimation: false});
    }
  }

  render() {

    const client = this.props.location.state.selectedClientObject;

    if(this.state.redirectTo !== '') {
      return (
        <Navigate to={this.state.redirectTo} state={{selectedClientObject: this.state.selectedClientObject}} />
      );
    }

    return (
      <div style={styles.main}>

        <div style={styles.content_view} ref={parent => {this.parent = parent}}>

          <div
            style={{
              display: this.state.modalVisible ? 'block' : 'none',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              alignSelf: 'center',
              backgroundColor: 'rgba(0,0,0,.75)',
              zIndex: 80,
              position: 'fixed',
            }}>
            <div style={styles.modal_container}>
              <a onClick={() => {this.setModalVisible(!this.state.modalVisible)}}>
                <div style={styles.modal_close_text}><AiOutlineCloseCircle size={35} color='#000'/></div>
              </a>
              <div style={styles.modal_body_container}>
                <div style={styles.modal_body}>
                  <div style={styles.modal_body_text}>Measurements for {this.state.modalTitle}.</div>
                </div>
                <div style={styles.modal_measure}>
                  {this.state.modalType === 'slider'
                  ? <div style={styles.modal_measure_container}>
                    <OsSlider
                      value={this.state.modalValue}
                      unit = {this.state.modalCountType}
                      onChange={value => this.setState({ modalValue: value })}
                    />
                    </div>
                  : null
                  }
                  {this.state.modalType === 'count'
                  ? <input type="number"
                      style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                      onChange={(e) => this.setState({modalValue: e.target.value})}
                      value={this.state.modalValue.toString()}
                    />
                  : null
                  }
                  {this.state.modalType === 'duration'
                  ? <div style={styles.modal_measure_container}>
                    <OsStepper
                      style={styles.modal_measure_container}
                      value={this.state.modalValue}
                      unit = {this.state.modalCountType}
                      onIncrement={() => this.increment(this.state.modalValue)}
                      onDecrement={() => this.decrement(this.state.modalValue)}
                    />
                    </div>
                  : null
                  }
                  {this.state.modalType === 'freetext'
                  ? <input type="text"
                      style={{height: 40, borderColor: 'gray', borderWidth: 1}}
                      onChange={(e) => this.setState({modalValue: e.target.value})}
                      value={this.state.modalValue.toString()}
                    />
                  : null
                  }
                  {this.state.modalType === 'multiselect' && this.state.modalValue.steps.length > 1
                    ?
                    <div style={styles.modal_measure_fixed_height}>
                    { this.state.modalValue.steps.map((step, key)=>(
                      <div key={key} style={styles.modal_data_picker}>
                        <div style={styles.modal_data_1a}>
                          <input type="checkbox"
                            checked={this.state.modalChecked[key]}
                            onClick={() => this.onModalCheckboxChange(!(this.state.modalChecked[key]),key)}
                          />
                        </div>
                        <div style={styles.modal_data_2a}>
                          <div style={styles.modal_data_2a_text}>{step}</div>
                        </div>
                        <div style={styles.modal_data_3a}>
                          <div style={{flex:0}}>
                            {this.state.modalSelected[key] ?
                              <div style={styles.modal_data_button_text}>{this.state.modalSelected[key]}</div>
                              :
                              <div style={styles.modal_data_button_text}>Select</div>
                            }
                            {this.state.modalValue.levelsLabels.map((levelval,levelkey) => {
                              return (
                                <a key={levelkey} style={styles.modal_data_button} onClick={() => {
                                  this.onModalSelectChange(this.state.modalValue.levelsLabels[levelkey],levelkey)
                                }}>{levelval}</a>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    ))}
                    </div>
                    : null
                  }
                </div>
                <div style={styles.modal_save}>
                  {this.state.modalType === 'multiselect' && typeof this.state.modalValue === 'object' && this.state.modalValue.steps.length == 1 ?
                    null
                  :
                    <a
                      onClick={() => {this.setModalSave();}}
                      style={styles.checkout_btn}>
                      <div style={styles.checkout_btn_text}>SAVE NEW DATA</div>
                    </a>
                  }
                </div>
                <div style={styles.modal_save_below}>
                {this.state.modalData
                  ?
                  this.state.modalData.map((data, key)=>(
                    <div key={key} style={styles.modal_data}>
                      <div style={styles.modal_data_1}>
                        <a
                          onClick={() => {this.deleteDataItem(this.state.modalSection,this.state.modalId,key);}}
                          style={styles.modal_delete_text}>
                          <AiOutlineDelete size={34} color='#f34242'/>
                        </a>
                      </div>
                      <div style={styles.modal_data_2}><div>{this.displayTime(data.timeStamp)}</div></div>
                      <div style={styles.modal_data_4}>
                      {typeof data.value === 'object' ?
                        <div>
                          {data.value.levels.map((levelsdata,levelskey) => {
                            if(data.value.steps[levelskey]){
                              return levelsdata+' ';
                            } else {
                              return ' - ';
                            }
                          })}
                        </div>
                        :
                        <div>{data.value}</div>
                      }
                      </div>
                    </div>
                  ))
                  :
                  null
                }
                </div>
              </div>
            </div>
          </div>

          <div style={styles.client_view}>
            <div style={styles.name_txt}>{client.clientName}</div>
            <div style={styles.address_txt}>Data Collection</div>
          </div>

          <div style={styles.overlay_content_bg}>

            <div style={styles.checkin_box}>
              <div style={styles.top_box}>
                <div style={styles.top_box_label}>Date:</div>
                <div style={styles.top_box_text}>{this.displayDate(client.clientDate)}</div>
              </div>
            </div>

            <div style={styles.status_box}>

              {this.state.sessionDataSource.map((item,key) => (
              <div style={styles.detail_profile_bg} key={key}>
                {this.state.loadingAnimation &&
                  <div style={styles.loading_container}>
                    <AiOutlineLoading className="icon-spin" size="large" color="#1e79b9" />
                  </div>
                }
                <div style={styles.main_view_row}>
                  <div style={styles.left}>
                    <AiOutlineCheckCircle size={25} color={item.completionPercent == 100 ? '#42c502' : '#b6b6b6'} />
                  </div>
                  <div style={styles.dataRow}>
                    <div style={styles.details}>
                      <div style={styles.name}>{item.title}</div>
                    </div>
                    {item.title.includes('Behavior') == false ?
                    <div style={styles.details}>
                      <div style={styles.completionText}>{item.completionText}</div>
                    </div>
                    : null }
                    {item.title.includes('Behavior') == false ?
                    <div style={styles.completionPercent}>
                      <div style={{
                        height: 8,
                        flex:item.completionPercent,
                        backgroundColor: (item.completionPercent == 100 ? '#42c502' : '#07c6fd'),
                        borderRadius: 4
                      }}></div>
                      <div style={{
                        backgroundColor: 'rgba(0,0,0,0)',
                        height: 8,flex: 90,
                        borderTopRightRadius: 4,
                        borderBottomRightRadius: 4,
                        flex:(100-item.completionPercent)
                      }}></div>
                    </div>
                   : null }
                  </div>
                </div>
                <div style={styles.measurement}>
                  <div style={styles.measurement_header}>
                    <div style={styles.measurement_header_1}><div style={styles.measurement_header_text_left}>Behavior</div></div>
                    <div style={styles.measurement_header_2}><div style={styles.measurement_header_text_right}>Measure</div></div>
                  </div>
                  {item.measurements.map((measurement, mkey)=>(
                    <div key={mkey} style={styles.measurement_body}>
                      <div style={styles.measurement_body_1}>
                        <div style={styles.measurement_name}>
                          {measurement.required == 1 && item.title == 'Goals' ? (<div style={styles.required}>*</div>) : null}
                          <div style={styles.measurement_name_text}>{measurement.title}</div>
                        </div>
                        <div style={styles.measurement_average}>
                          {measurement.average > 0 ?
                            <div style={styles.measurement_average_text}>Avg: {measurement.average}{measurement.measure}</div>
                          :
                            null
                          }
                        </div>
                      </div>
                      <div style={styles.measurement_body_2}>
                        {typeof measurement.startingVal === 'object' && measurement.startingVal.steps.length == 1 ?
                          <div style={styles.measurement_button_container}>
                            <a style={styles.measurement_button_1} onClick={() => this.handleAdd(measurement,mkey,item.itemkey)}>
                              <div style={styles.measurement_button_1_text}>View</div>
                            </a>
                            <a style={{
                              borderWidth: 4,
                              borderColor: '#1ebd25',
                              borderRadius: 0,
                              paddingLeft:4,
                              paddingRight:4,
                              flex:2,
                              justifyContent: 'center',
                              backgroundColor:(measurement.title == this.state.modalTitle && measurement.startingVal.levelsLabels[0] == this.state.modalSelected) ?'#1ebd25' : '#FFFFFF'
                            }} onClick={() => this.handleAddPlus(measurement,mkey,item.itemkey)}>
                              <div style={{
                                fontSize: moderateScale(14),
                                textAlign: 'center',
                                fontFamily: font_type.FontBold,
                                color: (measurement.title == this.state.modalTitle && measurement.startingVal.levelsLabels[0] == this.state.modalSelected) ? '#FFF' : '#1ebd25'
                              }}>{measurement.startingVal.levelsLabels[0]}</div>
                            </a>
                            <a style={{
                              borderWidth: 4,
                              borderColor: '#bd1e1e',
                              borderRadius: 0,
                              paddingLeft:4,
                              paddingRight:4,
                              flex:2,
                              justifyContent: 'center',
                              backgroundColor:(measurement.title == this.state.modalTitle && measurement.startingVal.levelsLabels[1] == this.state.modalSelected) ?'#bd1e1e' : '#FFFFFF'
                            }} onClick={() => this.handleAddMinus(measurement,mkey,item.itemkey)}>
                              <div style={{
                                fontSize: moderateScale(14),
                                textAlign: 'center',
                                fontFamily: font_type.FontBold,
                                color: (measurement.title == this.state.modalTitle && measurement.startingVal.levelsLabels[1] == this.state.modalSelected) ? '#FFF' : '#bd1e1e'
                              }}>{measurement.startingVal.levelsLabels[1]}</div>
                            </a>
                          </div>
                          :
                          <div style={styles.measurement_button_container}>
                            <a style={styles.measurement_button} onClick={() => this.handleAdd(measurement,mkey,item.itemkey)}>
                              <div style={styles.measurement_button_text}>ADD </div><AiOutlinePlus size={16} color='#FFFFFF'/>
                            </a>
                          </div>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              ))}

              <a style={styles.checkout_btn} onClick={() => {this.finalDataSave(true)}}>
                <div style={styles.checkout_btn_text}>SAVE ALL DATA & CLOSE</div>
              </a>

            </div>

          </div>

        </div>

      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    user: state.user.user
  }
}

export default withRouter(connect(mapStateToProps)(ClientDataCollection));

const styles = {
  header: {
		backgroundColor: '#FFF',
		borderBottomWidth: 0,
		elevation: 0,
		padding: 20,
	},
  back_arrow: {
    width: 30,
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
	left: {
		flex: 0.5,
		backgroundColor: 'rgba(0,0,0,0)',
	},
  body: {
		flex: 3,
		alignItems: 'center',
		backgroundColor: 'rgba(0,0,0,0)'
	},
	text_title: {
		color: 'white',
		fontSize: moderateScale(17),
		marginTop: 10,
		alignSelf: 'center',
		fontFamily: font_type.FontBold,
	},
	right: {
		flex: 0.5,
    textAlign: 'right'
	},
  main: {
    backgroundColor: '#ebebeb',
  },
  setting_icon: {
  	height:30,
  	width:30,
  	resizeMode: 'contain'
  },
  client_view:{
    display: 'flex',
    backgroundColor: "#1e7bbd",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
  },
  content_view:{
    backgroundColor: '#EEE',
    justifyContent:'flex-start',
    alignItems:'center',
  },
  name_txt: {
    color: 'white',
    fontSize: moderateScale(18),
    textAlign: 'center',
    fontFamily: font_type.FontBold,
  },
  address_txt: {
    color: '#d5eaf9',
    fontSize: moderateScale(12),
    textAlign: 'center',
    fontFamily: font_type.FontBold,
  },
  modal_container: {
    width: '90%',
    height: '90%',
    alignSelf: 'center',
    paddingTop:20,
    paddingBottom:20,
    paddingLeft:30,
    paddingRight:30,
    backgroundColor: 'rgba(255,255,255,1)',
    position: 'absolute',
    top: '5%',
    left: '5%',
    overflow: 'auto'
  },
  loading_container: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    paddingTop:0,
    paddingBottom:0,
    paddingLeft:0,
    paddingRight:0,
    backgroundColor: 'rgba(255,255,255,.8)',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 500,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  modal_close_text: {
    height:35,
    textAlign:'right'
  },
  modal_delete_text: {
    height:34,
    width:34,
    textAlign:'center',
    backgroundColor:'#FFF',
  },
  modal_body_container: {
    backgroundColor:'rgba(0,0,0,.0)',
  },
  modal_body: {
    paddingTop:5,
    paddingBottom:5,
  },
  modal_body_text: {
    color: '#9e9e9e',
    fontSize: moderateScale(12),
    textAlign: 'center',
    fontFamily: font_type.FontBold,
  },
  modal_measure: {
    paddingTop:15,
    paddingBottom:0,
  },
  modal_measure_container: {
    height:100,
    paddingTop:15,
    width: '80%',
  },
  modal_save: {
    paddingTop:10,
    paddingBottom:10,
  },
  required: {
    color: '#bd2026',
    fontFamily: font_type.FontBold,
  },
  measurement: {
    paddingTop:20,
    paddingBottom:20,
    paddingLeft:30,
    paddingRight:30
  },
  measurement_header: {
    display:'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth:1,
    borderBottomColor:'#ebebeb',
    paddingBottom:5,
    marginBottom:5
  },
  measurement_header_1: {
    flex: 2,
  },
  measurement_header_2: {
    flex: 3,
  },
  measurement_header_3: {
    flex: 3,
  },
  measurement_header_text_left: {
    color: "#6d6d6d",
    fontSize: moderateScale(15),
    textAlign: 'left',
  },
  measurement_header_text_right: {
    color: "#6d6d6d",
    fontSize: moderateScale(15),
    textAlign: 'right',
  },
  measurement_body: {
    display:'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5
  },
  measurement_body_1: {
    display:'flex',
    flex: 2,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  measurement_body_2: {
    flex: 3,
  },
  measurement_name: {
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  measurement_name_text: {
    fontSize: moderateScale(12),
		textAlign: 'left',
		fontFamily: font_type.FontLight,
    paddingRight: 15,
  },
  measurement_average: {
    fontSize: moderateScale(13),
		textAlign: 'left',
		fontFamily: font_type.FontBold,
  },
  measurement_average_text: {
    fontSize: moderateScale(13),
		textAlign: 'left',
		fontFamily: font_type.FontBold,
    paddingRight: 5,
  },
  tooltip_style: {
    backgroundColor: "#1e79b9",
    color: "#FFFFFF",
  },
  measurement_button:{
    display:'flex',
    flexDirection: 'row',
		backgroundColor: "#1e79b9",
		borderRadius: 0,
    padding:10,
    cursor: 'pointer',
    alignItems: 'center'
	},
	measurement_button_text:{
		color: "#fff",
		fontSize: moderateScale(14),
		textAlign: 'center',
		fontFamily: font_type.FontBold,
    marginRight:5,
	},
  measurement_button_container:{
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
	},
  measurement_button_1:{
		backgroundColor: "#FFF",
    borderWidth: 4,
    borderColor: '#1e7bbd',
		borderRadius: 0,
    paddingLeft:4,
    paddingRight:4,
    flex:3,
    justifyContent: 'center',
    cursor: 'pointer',
	},
	measurement_button_1_text:{
		color: "#1e7bbd",
		fontSize: moderateScale(14),
		textAlign: 'center',
		fontFamily: font_type.FontBold,
	},
  measurement_button_2:{
		backgroundColor: "#FFF",
    borderWidth: 4,
    borderColor: '#1ebd25',
		borderRadius: 0,
    paddingLeft:4,
    paddingRight:4,
    flex:2,
    justifyContent: 'center',
	},
	measurement_button_2_text:{
		color: "#1ebd25",
		fontSize: moderateScale(14),
		textAlign: 'center',
		fontFamily: font_type.FontBold,
	},
  measurement_button_3:{
		backgroundColor: "#FFF",
    borderWidth: 4,
    borderColor: '#bd1e1e',
		borderRadius: 0,
    paddingLeft:4,
    paddingRight:4,
    flex:2,
    justifyContent: 'center',
	},
	measurement_button_3_text:{
		color: "#bd1e1e",
		fontSize: moderateScale(14),
		textAlign: 'center',
		fontFamily: font_type.FontBold,
	},
  measurement_button_4:{
		backgroundColor: "#1e7bbd",
    borderWidth: 4,
    borderColor: '#1e7bbd',
		borderRadius: 0,
    paddingLeft:5,
    paddingRight:5,
    justifyContent: 'center',
	},
	measurement_button_4_text:{
		color: "#FFF",
		fontSize: moderateScale(14),
		textAlign: 'center',
		fontFamily: font_type.FontBold,
	},
  modal_save_below: {
    paddingTop:10,
    paddingBottom:10,
    width: '90%',
  },
  modal_data: {
    display:'flex',
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: 10,
    marginBottom: 10,
    alignSelf: 'center',
    width: '90%',
    height: 34,
    backgroundColor:'#FFF',
  },
  modal_data_1: {
    flex: 1,
    justifyContent:'center',
    height: 34,
  },
  modal_data_2: {
    flex: 2,
    justifyContent:'center',
    //backgroundColor:'#75FF33',
    height: 34,
  },
  modal_data_3: {
    flex: 3,
    justifyContent:'center',
    height: 34,
  },
  modal_data_4: {
    flex: 4,
    justifyContent:'center',
    height: 34,
  },
  modal_data_row: {
    display:'flex',
    color: '#d5eaf9',
    fontSize: moderateScale(10),
    textAlign: 'left',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
  },
  modal_measure_fixed_height: {
    width: '90%',
    paddingTop:15,
    paddingBottom:15,
    backgroundColor:'#FFF',
  },
  modal_data_picker: {
    display:'flex',
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    marginBottom: 0,
    width: '90%',
    alignSelf: 'center',
    backgroundColor:'#FFF',
  },
  modal_data_1a: {
    display:'flex',
    paddingTop: 0,
    paddingBottom: 0,
    width: '10%',
    backgroundColor:'#FFF',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottomWidth:1,
    borderBottomColor:'#ebebeb',
  },
  modal_data_2a: {
    display:'flex',
    paddingTop: 0,
    paddingBottom: 0,
    width: '60%',
    backgroundColor:'#FFF',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottomWidth:1,
    borderBottomColor:'#ebebeb',
  },
  modal_data_2a_text: {
    color: '#6d6d6d',
    fontSize: moderateScale(10),
    lineHeight: moderateScale(11),
  },
  modal_data_3a: {
    display:'flex',
    paddingTop: 0,
    paddingBottom: 0,
    width: '20%',
    backgroundColor:'#FFF',
    flexDirection: 'column',
    justifyContent: 'center',
    borderBottomWidth:1,
    borderBottomColor:'#ebebeb',
  },
  modal_data_button:{
		backgroundColor: "#1e79b9",
		borderRadius: 0,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    alignSelf: 'center',
	},
  modal_data_button_text:{
    color: "#fff",
		fontSize: moderateScale(11),
		textAlign: 'center',
		fontFamily: font_type.FontBold,
    lineHeight: 1,
  },
  overlay_content_bg: {
    display:'flex',
    flexDirection: 'column',
    paddingTop: 0,
    alignSelf: 'center',
  },
  status_box: {
    display:'flex',
    backgroundColor: '#FFF',
    alignSelf: 'center',
    borderRadius: 0,
    padding: 0,
    marginBottom: 10,
    flexDirection: 'column',
  },
  detail_profile_bg: {
    display:'flex',
    flexDirection: 'column',
    padding: 0,
    margin:0
  },
  checkin_box: {
    display:'flex',
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    alignSelf: 'center',
    borderRadius: 0,
    padding: 20,
    width:'100%',
    boxSizing: 'border-box',
    justifyContent: 'center',
  },
  top_box: {
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  top_box_label: {
    color: "#9e9e9e",
		fontSize: moderateScale(14),
		textAlign: 'left',
		fontFamily: font_type.FontBold,
  },
  top_box_text: {
    color: "#222222",
		fontSize: moderateScale(14),
		textAlign: 'left',
		fontFamily: font_type.FontBold,
    marginLeft:10,
  },
  checkout_btn:{
		backgroundColor: "#1e79b9",
		borderRadius: 4,
    padding:'17px 40px 20px 40px',
		justifyContent: 'space-between',
		alignItems: 'center',
		alignSelf: 'center',
    display: 'inline-block',
    lineHeight: 1,
    cursor: 'pointer',
	},
	checkout_btn_text:{
		color: "#fff",
		fontSize: moderateScale(16),
		justifyContent: 'space-between',
		textAlign: 'center',
		fontFamily: font_type.FontBold,
	},
  main_view_row:{
    display:'flex',
    flexDirection: 'row',
    marginBottom: 0,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 30,
    paddingLeft: 30,
    alignItems: 'center',
    backgroundColor: '#f1f1f1',
  },
  main_view_body:{
    display:'flex',
    flexDirection: 'row',
    marginBottom: 0,
    paddingTop: 15,
    paddingBottom: 15,
    paddingRight: 30,
    paddingLeft: 30,
    alignItems: 'center',
    backgroundColor: '#FFF',
  },
  dataRow:{
    marginLeft: 15,
    display:'flex',
    flexDirection: 'column',
    flex: 3,
    textAlign: 'left'
  },
  details:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    textAlign: 'left'
  },
  name:{
    color: "#6d6d6d",
    fontSize: moderateScale(16),
    textAlign: 'left'
  },
  completionText: {
    textAlign: 'left',
    color: "#9e9e9e",
    fontSize: moderateScale(12),
  },
  completionPercent: {
    display:'flex',
    backgroundColor: "#eaeaea",
    borderRadius: 4,
    height: 8,
    flex: 1,
    marginTop: 10,
    flexDirection:'row'
  },
};
