
export function apiCheckToken (token) {
  return fetch('https://api.kidslifesolutions.com/index.php?format=ajax&action=user&command=status&token='+token, {
      method: 'POST',
    })
    .then((response) => response.json())
    .then((responseJson) => { return responseJson; })
    .catch((error) => {console.error(error);});
}

export function apiLogIn (username, password) {
  return fetch('https://api.kidslifesolutions.com/index.php?format=ajax&action=user&command=login&username='+username+'&password='+password, {
      method: 'POST',
    })
    .then((response) => response.json())
    .then((responseJson) => { return responseJson; })
    .catch((error) => {console.error(error);});
}

export function apiLogOut (username, password) {
  return fetch('https://api.kidslifesolutions.com/index.php?format=ajax&action=user&command=logout', {
      method: 'POST',
    })
    .then((response) => response.json())
    .then((responseJson) => { return responseJson; })
    .catch((error) => {console.error(error);});
}

export function apiGetClients (token, page) {
  return fetch('https://api.kidslifesolutions.com/index.php?format=ajax&action=clients&command=list&sessionid='+token+'&page='+page, {
      method: 'POST',
    })
    .then((response) => response.json())
    .then((responseJson) => { return responseJson; })
    .catch((error) => {console.error(error);});
}

export function apiGetClient (token, clientid, version) {
  return fetch('https://api.kidslifesolutions.com/index.php?format=ajax&action=clients&command=detail&sessionid='+token+'&clientid='+clientid+'&version='+version, {
      method: 'POST',
    })
    .then((response) => response.json())
    .then((responseJson) => { return responseJson; })
    .catch((error) => {console.error(error);});
}

export function apiClientCheckIn (token, clientid, state) {
  return fetch('https://api.kidslifesolutions.com/index.php?format=ajax&action=clients&command=checkin&sessionid='+token+'&clientid='+clientid, {
      method: 'POST',
      headers: {'Accept':'application/json'},
      body: JSON.stringify(state)
    })
    .then((response) => response.json())
    .then((responseJson) => { return responseJson; })
    .catch((error) => {console.error(error);});
}

export function apiClientCheckOut (token, clientid, state, version) {
  return fetch('https://api.kidslifesolutions.com/index.php?format=ajax&action=clients&command=checkoutv2&sessionid='+token+'&clientid='+clientid+'&version='+version, {
      method: 'POST',
      headers: {'Accept':'application/json'},
      body: JSON.stringify(state)
    })
    .then((response) => response.json())
    .then((responseJson) => { return responseJson; })
    .catch((error) => {console.error(error);});
}

export function apiClientSupervisorCheckOut (token, clientid, state, version) {
  return fetch('https://api.kidslifesolutions.com/index.php?format=ajax&action=clients&command=supervisorcheckoutv2&sessionid='+token+'&clientid='+clientid+'&version='+version, {
      method: 'POST',
      headers: {'Accept':'application/json'},
      body: JSON.stringify(state)
    })
    .then((response) => response.json())
    .then((responseJson) => { return responseJson; })
    .catch((error) => {console.error(error);});
}

export function apiClientDataUpdate (token, clientid, datapacket) {
  return fetch('https://api.kidslifesolutions.com/index.php?format=ajax&action=clients&command=dataUpdate&sessionid='+token+'&clientid='+clientid, {
      method: 'POST',
      body: JSON.stringify({token: token, clientid: clientid, datapacket: datapacket})
    })
    .then((response) => response.json())
    .then((responseJson) => { return responseJson; })
    .catch((error) => {console.error(error);});
}
