import { combineReducers } from 'redux';
import user from './user';
import clients from './clients';
import note from './note';

const rootReducer = combineReducers({
  user,
  clients,
  note
});

export default rootReducer
