import React, { Component } from "react";
import { connect } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  useParams,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import {
  AiOutlineCheckCircle,
  AiFillCaretRight,
  AiFillCaretDown,
  AiOutlineLoading,
} from "react-icons/ai";

import { timeToString, font_type } from "../utils/helpers";
import { apiClientCheckOut, apiClientSupervisorCheckOut } from "../utils/api";

const moderateScale = (size) => size + "px";

const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <WrappedComponent
      {...props}
      params={params}
      navigate={navigate}
      location={location}
    />
  );
};

const profileDetails = [
  {
    id: "1",
    name: "Data Collection",
    completionspan: "0% Complete",
    completionPercent: 0,
    status: 0,
  },
  {
    id: "2",
    name: "Final Notes & Signatures",
    completionspan: "0% Complete",
    completionPercent: 0,
    status: 0,
  },
];

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

class ClientPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: false,
      profileDetailsSource: profileDetails,
      profileDetails: profileDetails,
      finalNotes: "",
      signature1: null,
      signature2: null,
      signature3: null,
      signatureMode: 1,
      showSig: false,
      checkedIn: false,
      checkedInTime: 0,
      checkedInName: "",
      caregivername: "",
      checkedOut: false,
      checkedOutTime: 0,
      clientid: props.location?.state?.selectedClientObject?.clientId,
      clientname: props.location?.state?.selectedClientObject?.clientName,
      clientcode: props.location?.state?.selectedClientObject?.clientCode,
      datapacket: null,
      loadingAnimation: false,
      reloadData: true,
      location: null,
      errorMessage: null,
      checkedInLocation: null,
      teleHealthSession: false,
      redirectTo: "",
      selectedClientObject: props.location?.state?.selectedClientObject,
    };
    //console.log('Client Page THIS2', props.location?.state?.selectedClientObject?.clientId);
    this.storeLocation = this.storeLocation.bind(this);
    this.showLocError = this.showLocError.bind(this);
    this.sigCanvas = React.createRef();
  }

  componentDidMount() {
    //const { location } = this.props;
    // this.focusListener = location.addListener('didFocus', () => {
    //   //this.getClient();
    // });
    this.getClient();
    this.getLocation();
  }

  componentWillUnmount() {
    //this.focusListener.remove();
  }

  sigClear = () => {
    //console.log(this);
    this.setState({ showSig: false }, () => {
      if (this.sigCanvas.current) {
        this.sigCanvas.current.clear();
      }
      if (this.state.signatureMode === 1) {
        this.setState({ signature1: "" });
      } else if (this.state.signatureMode === 2) {
        this.setState({ signature2: "" });
      } else {
        this.setState({ signature3: "" });
      }
    });
  };

  sigTrim = () => {
    this.setState({
      //trimmedDataURL: this.sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    });
  };

  getClient() {
    let clientString =
      "client" + this.props.location?.state?.selectedClientObject?.clientId;
    const existingSessionData = localStorage.getItem(
      "KidsLife:" + clientString
    );
    if (existingSessionData) {
      this.setState({ loadingAnimation: true });
      const dataJson = JSON.parse(existingSessionData);
      if (dataJson) {
        //Local storage has an existing session open for this client.
        this.setState(
          {
            loadingData: false,
            finalNotes: dataJson.sessionData.finalNotes,
            signature1: dataJson.sessionData.signature1,
            signature2: dataJson.sessionData.signature2,
            signature3: dataJson.sessionData.signature3,
            checkedInTime: dataJson.sessionData.checkedInTime,
            checkedInName: dataJson.sessionData.checkedInName,
            caregivername: dataJson.sessionData.caregivername,
            checkedOutTime: dataJson.sessionData.checkedOutTime,
            checkedIn: dataJson.sessionData.checkedIn,
            checkedOut: dataJson.sessionData.checkedOut,
            checkedInLocation: dataJson.sessionData.checkedInLocation,
            datapacket: dataJson.sessionData.clientData,
          },
          () => {
            this.updateFinalComplete();
          }
        );
      } else {
        //console.log('Data packet: ',this.props.location?.state?.selectedClientObject?.clientData);
        this.setState(
          {
            loadingData: false,
            datapacket:
              this.props.location?.state?.selectedClientObject?.clientData,
          },
          () => {
            this.updateFinalComplete();
          }
        );
        //Otherwise, get it from nav params
      }
      this.setState({ loadingAnimation: false });
    } else {
      this.setState(
        {
          loadingData: false,
          datapacket:
            this.props.location?.state?.selectedClientObject?.clientData,
        },
        () => {
          this.updateFinalComplete();
        }
      );
      //Otherwise, get it from nav params
    }
  }

  // _getLocationAsync = async () => {
  //   let { status } = await Permissions.askAsync(Permissions.LOCATION);
  //   if (status === 'granted') {
  //     let options = {
  //       //maximumAge: 60000, // only for Android
  //       //accuracy: Location.Accuracy.Balanced,
  //       enableHighAccuracy: true,
  //     }
  //     let location = await Location.getCurrentPositionAsync(options);
  //     this.setState({ location: location, errorMessage: '' });
  //   } else {
  //     this.setState({
  //       errorMessage: 'We could not find your location. Verify that you have allowed location services for this app. There might be a delay on certain mobile devices of up to 30 seconds, please wait until trying again.',
  //     });
  //   }
  // };

  storeLocation(loc) {
    const locationObject = {
      latitude: loc.coords.latitude,
      longitude: loc.coords.longitude,
      accuracy: loc.coords.accuracy,
      altitude: loc.coords.altitude,
      altitudeAccuracy: loc.coords.altitudeAccuracy,
      heading: loc.coords.heading,
      speed: loc.coords.speed,
    };
    this.setState({ location: locationObject, errorMessage: "" });
  }

  getLocation() {
    //console.log(navigator);
    //console.log('getLocation',navigator);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.storeLocation,
        this.showLocError,
        { enableHighAccuracy: true }
      );
    } else {
      this.showLocError("");
    }
  }

  showLocError(error) {
    let finalError = "";
    //console.log('showLocError',error);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        finalError = "You denied the request for device location.";
        break;
      case error.POSITION_UNAVAILABLE:
        finalError = "Device location information is unavailable.";
        break;
      case error.TIMEOUT:
        finalError = "The request to get device location timed out.";
        break;
      case error.UNKNOWN_ERROR:
        finalError = "An unknown error occurred locating device.";
        break;
      default:
        finalError = "Geolocation is not supported by this browser.";
    }
    finalError +=
      " You cannot proceed without enabling location permissions in your browser settings.";
    confirmAlert({
      title: "ERROR",
      message: finalError,
      buttons: [{ label: "OK, Try Again", onClick: () => this.getLocation() }],
    });
  }

  teleHealthClick = () => {
    this.setState({ teleHealthSession: !this.state.teleHealthSession });
  };

  handleRowClick = (rowData) => {
    let clientString =
      "client" + this.props.location?.state?.selectedClientObject?.clientId;
    const existingSessionData = localStorage.getItem(
      "KidsLife:" + clientString
    );
    if (
      rowData.id == "1" &&
      this.state.checkedInTime > 0 &&
      this.state.checkedOut == false &&
      existingSessionData
    ) {
      //Data collection row, session is checked in, session is NOT checked out.  Or it is a supervisor.
      const newSessionData = {
        finalNotes: this.state.finalNotes,
        signature1: this.state.signature1,
        signature2: this.state.signature2,
        signature3: this.state.signature3,
      };
      const dataJson = JSON.parse(existingSessionData);
      if (dataJson) {
        const sessionData = { ...dataJson.sessionData, ...newSessionData };
        //console.log(sessionData);
        localStorage.setItem(
          "KidsLife:" + clientString,
          JSON.stringify({ sessionData: sessionData })
        );
        //Save their current progress before going to data page.
        this.setState({
          selectedClientObject: {
            clientId: this.state.clientid,
            clientName: this.state.clientname,
            clientDate: this.state.checkedInTime,
            clientCode: this.state.clientcode,
            clientData: this.state.datapacket,
          },
          redirectTo: "/datacollection",
        });
      }
    } else {
      if (rowData.id == "1") {
        confirmAlert({
          title: "ERROR",
          message: "Check In to the session first!",
          buttons: [
            { label: "Try again", onClick: () => console.log("Cancel") },
          ],
        });
      }
    }
  };

  handleSignature = () => {
    if (this.sigCanvas.current) {
      const signature = this.sigCanvas.current.toDataURL("image/png");
      //console.log(signature);
      //const signature = this.sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
      if (this.state.signatureMode === 1) {
        this.setState({ signature1: signature, showSig: false }, () => {
          this.updateFinalComplete();
        });
      } else if (this.state.signatureMode === 2) {
        this.setState({ signature2: signature, showSig: false }, () => {
          this.updateFinalComplete();
        });
      } else {
        this.setState({ signature3: signature, showSig: false }, () => {
          //this.updateFinalComplete();
        });
      }
      this.sigCanvas.current.clear();
    }
  };

  showSigBlock(sigNum) {
    this.setState({
      signatureMode: sigNum,
      showSig: true,
    });
  }

  updatespan = (text) => {
    //const { dispatch } = this.props
    this.setState(
      {
        finalNotes: text,
      },
      () => {
        this.updateFinalComplete();
      }
    );
  };

  updateFinalComplete = () => {
    let countComplete = 0;
    //if(this.state.checkedIn) {countComplete++;}
    if (this.state.finalNotes != "") {
      countComplete++;
    }
    if (this.state.signature1) {
      countComplete++;
    }
    if (this.state.signature2) {
      countComplete++;
    }
    //if(this.state.checkedOut) {countComplete++;}
    let percent = 0;
    //console.log('Complete: ',countComplete);
    //console.log('State: ',this.state);
    //return;
    let newProfileDetails = this.state.profileDetails;
    if (countComplete == 0) {
      newProfileDetails[1].completionPercent = percent;
      newProfileDetails[1].completionspan = percent.toFixed(0) + "% Complete";
    } else {
      percent = (countComplete / 3) * 100;
      newProfileDetails[1].completionPercent = percent;
      newProfileDetails[1].completionspan = percent.toFixed(0) + "% Complete";
    }
    if (this.props.user.supervisor) {
      newProfileDetails[1].completionspan = "Supervisor Mode";
    }
    if (percent == 100) {
      newProfileDetails[1].status = 1;
    } else {
      newProfileDetails[1].status = 0;
    }
    //console.log(this.state.profileDetails);
    //console.log(this.state.datapacket);

    //Now calculate the data completion
    let measurementsTotal = 0;
    let measurementsDone = 0;
    let percentageDone = 0;
    this.state.datapacket &&
      this.state.datapacket.map((section, sectionkey) => {
        section.measurements.map((measurementval, measurementkey) => {
          if (measurementval.required) {
            measurementsTotal++; //It's required, so add to total
            if (measurementval.data) {
              if (measurementval.data.length) {
                //Data has been entered.
                measurementsDone++;
              }
            }
          }
        });
      });
    percentageDone = Math.ceil((measurementsDone / measurementsTotal) * 100);
    newProfileDetails[0].completionPercent =
      measurementsTotal > 0 ? percentageDone : 100;
    newProfileDetails[0].completionspan =
      measurementsTotal > 0
        ? percentageDone.toFixed(0) + "% Complete"
        : "100% Complete";
    if (percentageDone == 100) {
      newProfileDetails[0].status = 1;
    } else {
      newProfileDetails[0].status = 0;
    }
    if (this.props.user.supervisor) {
      newProfileDetails[0].completionspan = "Supervisor Mode";
    }
    //console.log('newProfileDetails: ',newProfileDetails);
    this.setState({
      profileDetailsSource: newProfileDetails,
    });
  };

  displayDate(timestamp) {
    timestamp = typeof timestamp !== "undefined" ? timestamp : Date.now();
    const strTime = timeToString(timestamp);
    return strTime;
  }

  checkInNow() {
    //console.log(this.state.location);
    const { location } = this.props;

    this.setState({ loadingAnimation: true });
    if (!this.state.location) {
      this.setState({
        loadingAnimation: false,
        errorMessage:
          "We could not find your location to check-in. Verify that you have allowed location services for this app and try again.",
      });
    } else {
      console.log("checkInNow", this.state);

      let clientString =
        "client" + location.state.selectedClientObject.clientId;
      let sessionData = {
        clientData: location.state.selectedClientObject.clientData,
        finalNotes: "",
        checkedIn: true,
        checkedInTime: Date.now(),
        checkedInName: this.state.checkedInName,
        caregivername: this.state.caregivername,
        checkedOut: false,
        checkedOutTime: 0,
        checkedInLocation: this.state.location,
        signature1: "",
        signature2: "",
        signature3: "",
        telehealthsession: this.state.telehealthsession,
      };
      localStorage.setItem(
        "KidsLife:" + clientString,
        JSON.stringify({ sessionData })
      );
      confirmAlert({
        title: "Checked In",
        message:
          "The session has been checked in. Complete all data collection and notes before completing check out.",
        buttons: [{ label: "OK", onClick: () => console.log("OK") }],
      });
      this.setState(
        {
          checkedIn: true,
          checkedInTime: Date.now(), //JS wants milliseconds
          checkedInLocation: this.state.location,
          datapacket: location.state.selectedClientObject.clientData,
        },
        () => {
          //console.log('Check in complete',this.state);
          this.updateFinalComplete();
        }
      );
      this.setState({ loadingAnimation: false });
    }
  }

  checkOutNow() {
    const { user, location } = this.props;
    //console.log(this.state);
    let readyToCheckOut = true;
    if (!this.state.checkedIn) {
      readyToCheckOut = false;
    }
    if (this.state.finalNotes == "") {
      readyToCheckOut = false;
    }
    if (!this.state.signature1) {
      readyToCheckOut = false;
    }
    if (!this.state.signature2) {
      readyToCheckOut = false;
    }
    if (!readyToCheckOut) {
      confirmAlert({
        title: "ERROR",
        message: "Final notes and signatures are not complete yet!",
        buttons: [{ label: "Try again", onClick: () => console.log("Cancel") }],
      });
    } else {
      console.log("checkOutNow", this.state);
      confirmAlert({
        title: "CONFIRM",
        message:
          "Are you sure you want to complete this session? It cannot be re-opened once checked out.",
        buttons: [
          {
            label: "Yes, Complete Check-Out",
            onClick: () => {
              this.setState({ loadingAnimation: true });
              apiClientCheckOut(
                user.session,
                location.state.selectedClientObject.clientId,
                this.state,
                "3.0.5"
              )
                .then((checkoutResult) => {
                  //console.log('Check Out: ', checkoutResult);
                  if (checkoutResult.error == 0) {
                    confirmAlert({
                      title: "Checked Out",
                      message:
                        "The session has been checked out and completed.",
                      buttons: [
                        { label: "OK", onClick: () => console.log("OK") },
                      ],
                    });
                    console.log("checkoutResult", checkoutResult);
                    this.setState(
                      {
                        checkedOut: true,
                        checkedOutTime: Date.now(),
                        checkedIn: false,
                        checkedInTime: 0,
                        checkedInName: "",
                        caregivername: "",
                        datapacket: null,
                        signature1: null,
                        signature2: null,
                        signature3: null,
                        telehealthsession: false,
                      },
                      () => {
                        let clientString =
                          "client" +
                          location.state.selectedClientObject.clientId;
                        localStorage.removeItem("KidsLife:" + clientString);
                        console.log("localStorage.removeItem", clientString);
                        this.setState({
                          redirectTo: "/",
                        });
                        //this.updateFinalComplete();
                      }
                    );
                  } else {
                    confirmAlert({
                      title: "ERROR",
                      message: checkoutResult.message,
                      buttons: [
                        {
                          label: "Cancel",
                          onClick: () => console.log("Cancel"),
                        },
                      ],
                    });
                  }
                  this.setState({ loadingAnimation: false });
                  return checkoutResult;
                })
                .catch((error) => {
                  console.error(error);
                  this.setState({ loadingAnimation: false });
                });
            },
          },
          { label: "No, Cancel", onClick: () => {} },
        ],
      });
    }
  }

  checkOutSupervisorNow() {
    const { user, location } = this.props;
    let readyToCheckOut = true;
    if (this.state.finalNotes == "") {
      readyToCheckOut = false;
    }
    if (!this.state.signature3) {
      readyToCheckOut = false;
    }
    if (!readyToCheckOut) {
      confirmAlert({
        title: "ERROR",
        message: "Notes and signatures are not complete yet!",
        buttons: [{ label: "Try again", onClick: () => console.log("Cancel") }],
      });
    } else {
      confirmAlert({
        title: "CONFIRM",
        message:
          "Are you sure you want to complete this session? It cannot be re-opened once checked out.",
        buttons: [
          { label: "No, Cancel", onClick: () => console.log("Cancel") },
          {
            label: "Yes, Complete Check-Out",
            onClick: () => {
              //console.log('Start: ', user.session, location.state.selectedClientObject.clientId, '3.0.5');
              this.setState({ loadingAnimation: true });
              apiClientSupervisorCheckOut(
                user.session,
                location.state.selectedClientObject.clientId,
                this.state,
                "3.0.5"
              )
                .then((supervisorCheckOutResult) => {
                  //console.log('Check Out: ', supervisorCheckOutResult);
                  if (supervisorCheckOutResult.error == 0) {
                    confirmAlert({
                      title: "Checked Out",
                      message:
                        "The supervisor review data has been saved for this session.",
                      buttons: [
                        { label: "OK", onClick: () => console.log("Cancel") },
                      ],
                    });
                    this.setState(
                      {
                        checkedOut: true,
                        checkedOutTime: Date.now(),
                        checkedIn: false,
                        checkedInTime: 0,
                        checkedInName: "",
                        caregivername: "",
                        datapacket: null,
                        signature1: null,
                        signature2: null,
                        signature3: null,
                      },
                      () => {
                        let clientString =
                          "client" +
                          location.state.selectedClientObject.clientId;
                        localStorage.removeItem("KidsLife:" + clientString);
                        this.setState({
                          redirectTo: "/",
                        });
                      }
                    );
                  } else {
                    confirmAlert({
                      title: "ERROR",
                      message: supervisorCheckOutResult.message,
                      buttons: [
                        {
                          label: "Cancel",
                          onClick: () => console.log("Cancel"),
                        },
                      ],
                    });
                  }
                  this.setState({ loadingAnimation: false });
                  return supervisorCheckOutResult;
                })
                .catch((error) => {
                  console.error(error);
                  this.setState({ loadingAnimation: false });
                });
            },
          },
        ],
      });
    }
  }

  render() {
    const style = `.m-signature-pad--footer
    .button {
      background-color: #1e7bbd;
      color: #FFF;
    }`;
    const client = this.props.location?.state?.selectedClientObject;

    if (!client) {
      return <></>;
    }

    if (this.state.redirectTo !== "") {
      return (
        <Navigate
          to={this.state.redirectTo}
          state={{ selectedClientObject: this.state.selectedClientObject }}
        />
      );
    }

    return (
      <div style={styles.main}>
        <div
          style={this.state.showSig ? styles.sig_view_on : styles.sig_view_off}
        >
          {this.state.signatureMode == 1 ? (
            <label style={styles.checkout_box_label}>Therapist Signature</label>
          ) : (
            <label style={styles.checkout_box_label}>Client Signature</label>
          )}
          <SignatureCanvas
            ref={this.sigCanvas}
            penColor="green"
            canvasProps={{
              style: {
                background: "#FFFFFF",
                width: "100%",
                height: 200,
                marginTop: 5,
                boxSizing: "border-box",
                border: "2px solid #CCCCCC",
                display: "block",
              },
              className: "sigCanvas",
            }}
          />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <a
              style={styles.signature_btn_confirm}
              onClick={this.handleSignature}
            >
              <span style={styles.checkin_btn_text}>Save Signature</span>
            </a>
            <a style={styles.signature_btn_cancel} onClick={this.sigClear}>
              <span style={styles.checkin_btn_text}>
                Clear/Cancel Signature
              </span>
            </a>
          </div>
        </div>
        <div style={styles.content_view}>
          <div style={styles.client_view}>
            <span style={styles.name_txt}>{client.clientName}</span>
            <span style={styles.address_txt}>Active</span>
          </div>
          {this.state.loadingAnimation ? (
            <div>
              <AiOutlineLoading
                className="icon-spin"
                size="large"
                color="#1e79b9"
              />
            </div>
          ) : (
            <div style={styles.overlay_content_bg}>
              <div style={styles.checkin_box}>
                {this.state.errorMessage ? (
                  <div>
                    {!this.state.location && (
                      <div>
                        <AiOutlineLoading
                          className="icon-spin"
                          size="large"
                          color="#1e79b9"
                        />
                      </div>
                    )}
                    <span style={styles.checkin_box_txt}>
                      {this.state.errorMessage}
                    </span>
                    <a
                      style={styles.checkin_btn}
                      onClick={() => {
                        this.getLocation();
                      }}
                    >
                      <span style={styles.checkin_btn_text}>Try Again</span>
                    </a>
                  </div>
                ) : (
                  <div>
                    {this.state.checkedIn ? (
                      <span style={styles.checkin_box_txt}>
                        This client session is checked in. Started at{" "}
                        {this.displayDate(this.state.checkedInTime)}.
                      </span>
                    ) : (
                      <span style={styles.checkin_box_txt}>
                        Check-in to your current location at{" "}
                        {this.displayDate()}.
                        <div>
                          <select
                            style={styles.checkin_box_select}
                            onChange={(e) =>
                              this.setState({ checkedInName: e.target.value })
                            }
                          >
                            <option value="">Select Check-In Location</option>
                            <option value="School">School</option>
                            <option value="Home">Home</option>
                            <option value="PPEC">PPEC</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </span>
                    )}
                    {this.state.checkedIn ? null : (
                      <div>
                        {this.props.user.supervisor ? (
                          <a onClick={() => this.teleHealthClick()}>
                            <div style={styles.telehealth_view_row}>
                              <input
                                type="checkbox"
                                style={styles.checkout_box}
                                checked={this.state.teleHealthSession}
                              />
                              <label style={styles.checkout_box_label}>
                                This is a Telehealth Session
                              </label>
                            </div>
                          </a>
                        ) : null}
                        <a
                          style={styles.checkin_btn}
                          onClick={() => {
                            this.checkInNow();
                          }}
                        >
                          <span style={styles.checkin_btn_text}>
                            CHECK-IN NOW
                          </span>
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div style={styles.status_box}>
                <div style={styles.detail_profile_bg}>
                  {this.state.profileDetailsSource.map((item, key) => (
                    <a
                      key={key}
                      onClick={() => this.handleRowClick(item)}
                      style={styles.detail_profile_bg_row}
                    >
                      <div style={styles.main_view_row}>
                        <div style={styles.left}>
                          {item.status == 1 ? (
                            <AiOutlineCheckCircle size="40" color="#42c502" />
                          ) : (
                            <AiOutlineCheckCircle size="40" color="#b6b6b6" />
                          )}
                        </div>
                        <div style={styles.dataRow}>
                          <div style={styles.details}>
                            <span style={styles.name}>{item.name}</span>
                          </div>
                          <div style={styles.details}>
                            <span style={styles.completionspan}>
                              {item.completionspan}
                            </span>
                          </div>
                          <div style={styles.completionPercent}>
                            <div
                              style={{
                                height: 8,
                                flex: item.completionPercent,
                                backgroundColor:
                                  item.completionPercent == 100
                                    ? "#42c502"
                                    : "#07c6fd",
                                borderRadius: 4,
                              }}
                            ></div>
                            <div
                              style={{
                                backgroundColor: "rgba(0,0,0,0)",
                                height: 8,
                                flex: 90,
                                borderTopRightRadius: 4,
                                borderBottomRightRadius: 4,
                                flex: 100 - item.completionPercent,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div style={styles.right}>
                          {item.id == "1" ? (
                            <AiFillCaretRight size="20" color="#b6b6b6" />
                          ) : (
                            <AiFillCaretDown size="20" color="#b6b6b6" />
                          )}
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
                {this.state.checkedIn && (
                  <div style={styles.notes_box}>
                    <div>
                      <label style={styles.checkout_box_label}>
                        Caregiver Name
                      </label>
                      <input
                        type="text"
                        style={styles.checkin_box_select}
                        onChange={(e) =>
                          this.setState({ caregivername: e.target.value })
                        }
                        placeholder="enter name..."
                      />
                    </div>
                    {this.props.user.supervisor ? (
                      <label style={styles.checkout_box_label}>
                        Supervisor Notes...
                      </label>
                    ) : (
                      <label style={styles.checkout_box_label}>
                        Therapy Notes...
                      </label>
                    )}
                    <textarea
                      style={styles.checkout_box_text}
                      rowSpan={5}
                      value={this.state.finalNotes}
                      onChange={(e) => this.updatespan(e.target.value)}
                    />
                  </div>
                )}
                {this.props.user.supervisor ? (
                  <div>
                    <a
                      onClick={() => {
                        this.showSigBlock(3);
                      }}
                    >
                      <div style={styles.preview}>
                        {this.state.signature3 &&
                        this.state.signature3 != "data:image/png;base64," ? (
                          <img
                            style={{
                              width: 335,
                              height: 100,
                              objectFit: "contain",
                            }}
                            src={this.state.signature3}
                          />
                        ) : (
                          <span style={styles.preview_txt}>
                            No supervisor signature yet, tap here to sign.
                          </span>
                        )}
                      </div>
                    </a>
                  </div>
                ) : null}
                {this.state.checkedIn && (
                  <div>
                    <a
                      onClick={() => {
                        this.showSigBlock(2);
                      }}
                    >
                      <div style={styles.preview}>
                        {this.state.signature2 &&
                        this.state.signature2 != "data:image/png;base64," ? (
                          <img
                            style={{
                              width: 335,
                              height: 100,
                              objectFit: "contain",
                            }}
                            src={this.state.signature2}
                          />
                        ) : (
                          <span style={styles.preview_txt}>
                            No adult signature yet, tap here to sign.
                          </span>
                        )}
                      </div>
                    </a>
                  </div>
                )}
                {this.state.checkedIn && (
                  <div>
                    {this.props.user.supervisor ? null : (
                      <a
                        onClick={() => {
                          this.showSigBlock(1);
                        }}
                      >
                        <div style={styles.preview}>
                          {this.state.signature1 ? (
                            <img
                              style={{
                                width: 335,
                                height: 100,
                                objectFit: "contain",
                              }}
                              src={this.state.signature1}
                            />
                          ) : (
                            <span style={styles.preview_txt}>
                              No RBT signature yet, tap here to sign.
                            </span>
                          )}
                        </div>
                      </a>
                    )}
                  </div>
                )}
                {this.state.checkedIn && (
                  <div>
                    {this.state.checkedOut ? (
                      <span style={styles.checkin_box_txt}>
                        This client session is checked out. Ended at{" "}
                        {this.displayDate(this.state.checkedOutTime)}.
                      </span>
                    ) : (
                      <div>
                        {this.props.user.supervisor ? (
                          <a
                            style={styles.checkout_btn}
                            onClick={() => {
                              this.checkOutSupervisorNow();
                            }}
                          >
                            <span style={styles.checkout_btn_text}>
                              SAVE SUPERVISOR NOTES
                            </span>
                          </a>
                        ) : (
                          <a
                            style={styles.checkout_btn}
                            onClick={() => {
                              this.checkOutNow();
                            }}
                          >
                            <span style={styles.checkout_btn_text}>
                              CHECK-OUT NOW
                            </span>
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(ClientPage));

const styles = {
  left: {
    flex: 0.5,
    backgroundColor: "rgba(0,0,0,0)",
  },
  right: {
    flex: 0.5,
    textAlign: "center",
  },
  main: {
    backgroundColor: "#ebebeb",
  },
  setting_icon: {
    height: 30,
    width: 30,
    resizeMode: "contain",
  },
  client_view: {
    display: "flex",
    backgroundColor: "#1e7bbd",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 30,
  },
  content_view: {
    backgroundColor: "#EEE",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  name_txt: {
    color: "white",
    fontSize: moderateScale(18),
    textAlign: "center",
    fontFamily: font_type.FontBold,
  },
  address_txt: {
    color: "#d5eaf9",
    fontSize: moderateScale(12),
    textAlign: "center",
    fontFamily: font_type.FontBold,
  },
  detail_profile_bg: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    margin: 0,
  },
  detail_profile_bg_row: {
    padding: 0,
    margin: 0,
  },
  overlay_content_bg: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
  },
  status_box: {
    backgroundColor: "#FFF",
    alignSelf: "center",
    borderRadius: 0,
    padding: 20,
    marginBottom: 3,
  },
  checkin_box: {
    alignSelf: "center",
    borderRadius: 0,
    padding: 30,
    marginBottom: 10,
  },
  checkin_box_txt: {
    color: "#9e9e9e",
    fontSize: moderateScale(16),
    textAlign: "center",
    marginBottom: 8,
    display: "inline-block",
  },
  checkin_box_select: {
    color: "#9e9e9e",
    fontSize: moderateScale(16),
    textAlign: "left",
    margin: 7,
    padding: 12,
    display: "inline-block",
    border: "1px solid #9e9e9e",
  },
  signature_btn_confirm: {
    backgroundColor: "#008000",
    borderRadius: 0,
    padding: "17px 20px 20px 20px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    display: "inline-block",
    lineHeight: 1,
    cursor: "pointer",
    flex: 2,
  },
  signature_btn_cancel: {
    backgroundColor: "#FF0000",
    borderRadius: 0,
    padding: "17px 20px 20px 20px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    display: "inline-block",
    lineHeight: 1,
    cursor: "pointer",
    flex: 1,
  },
  checkin_btn: {
    backgroundColor: "#1e79b9",
    borderRadius: 4,
    padding: "17px 40px 20px 40px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    display: "inline-block",
    lineHeight: 1,
    cursor: "pointer",
  },
  checkin_btn_text: {
    color: "#fff",
    fontSize: moderateScale(16),
    justifyContent: "space-between",
    textAlign: "center",
    fontFamily: font_type.FontBold,
  },
  notes_box: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  checkout_box_label: {
    marginBottom: 5,
    marginTop: 10,
    alignItems: "center",
    alignSelf: "center",
    color: "#6d6d6d",
    fontFamily: font_type.FontBold,
  },
  checkout_box_text: {
    marginBottom: 10,
    marginTop: 10,
    alignItems: "center",
    alignSelf: "center",
    borderRadius: 0,
    padding: 10,
    borderWidth: 3,
    borderColor: "#DDDDDD",
    borderStyle: "solid",
    width: "100%",
    height: 120,
    boxSizing: "border-box",
  },
  sig_view_on: {
    backgroundColor: "#EFEFEF",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    position: "fixed",
    bottom: "0px",
    left: "0px",
    width: "100%",
    zIndex: 200,
    borderTop: "1px solid #DDD",
    boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.15)",
    boxSizing: "border-box",
  },
  sig_view_off: {
    backgroundColor: "#EFEFEF",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    position: "fixed",
    top: "100%",
    left: "0px",
    width: "100%",
    zIndex: 200,
    borderTop: "1px solid #DDD",
    boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.15)",
    boxSizing: "border-box",
  },
  preview: {
    height: 120,
    backgroundColor: "#FFF",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 20,
    marginTop: 10,
    border: "3px dashed #DDD",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  preview_txt: {
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    fontSize: moderateScale(13),
    fontFamily: font_type.FontBold,
  },
  checkout_btn: {
    backgroundColor: "#1e79b9",
    borderRadius: 4,
    padding: "17px 40px 20px 40px",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "center",
    display: "inline-block",
    lineHeight: 1,
    cursor: "pointer",
  },
  checkout_btn_text: {
    color: "#fff",
    fontSize: moderateScale(16),
    justifyContent: "space-between",
    textAlign: "center",
    fontFamily: font_type.FontBold,
  },
  telehealth_view_row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 15,
    paddingBottom: 15,
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkout_box: {
    marginBottom: 0,
    marginRight: 20,
  },
  main_view_row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 15,
    paddingBottom: 15,
    alignItems: "center",
    borderRadius: 1,
    borderWidth: 0,
    borderColor: "#EEEEEE",
    borderStyle: "dashed",
    borderBottomWidth: 1,
  },
  dataRow: {
    marginLeft: 15,
    display: "flex",
    flexDirection: "column",
    flex: 3,
    textAlign: "left",
  },
  details: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "left",
  },
  name: {
    color: "#6d6d6d",
    fontSize: moderateScale(16),
    textAlign: "left",
  },
  completionspan: {
    textAlign: "left",
    color: "#9e9e9e",
    fontSize: moderateScale(12),
  },
  completionPercent: {
    backgroundColor: "#eaeaea",
    borderRadius: 4,
    height: 8,
    flex: 1,
    marginTop: 10,
    flexDirection: "row",
    display: "flex",
  },
  completionPercentActive: {
    backgroundColor: "#07c6fd",
    height: 8,
    flex: 10,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  completionPercentInactive: {
    backgroundColor: "rgba(0,0,0,0)",
    height: 8,
    flex: 90,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  container: {
    flex: 1,
    justifyContent: "center",
  },
  horizontal: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
};
