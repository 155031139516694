import { GET_CLIENTS } from '../actions'

function clients (state = {}, action) {
  switch (action.type) {
    case GET_CLIENTS :
      return {
        ...state,
        ...action.clients,
      }
    default :
      return state
  }
}

export default clients
