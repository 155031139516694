import React from 'react';

export function getDailyReminderValue () {
  return {
    today: "Don't forget to log your data today!"
  }
}

export const font_type = {
  FontLight: 'Noto Sans',
  FontBold : 'Noto Sans Bold'
};

export function setCookie(name, value, days) {
  let expires = "";
  if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function isBetween(num, x, y) {
  if (num >= x && num <= y) {
    return true;
  }
  return false;
}

export function calculateDirection(heading) {
  let direction = "";
  if (isBetween(heading, 0, 22.5)) {
    direction = "North";
  } else if (isBetween(heading, 22.5, 67.5)) {
    direction = "North East";
  } else if (isBetween(heading, 67.5, 112.5)) {
    direction = "East";
  } else if (isBetween(heading, 112.5, 157.5)) {
    direction = "South East";
  } else if (isBetween(heading, 157.5, 202.5)) {
    direction = "South";
  } else if (isBetween(heading, 202.5, 247.5)) {
    direction = "South West";
  } else if (isBetween(heading, 247.5, 292.5)) {
    direction = "West";
  } else if (isBetween(heading, 292.5, 337.5)) {
    direction = "North West";
  } else if (isBetween(heading, 337.5, 360)) {
    direction = "North";
  } else {
    direction = "Calculating";
  }
  return direction;
}

export function timeToString(time) {
  const date = new Date(time)
  let minutes = date.getMinutes().toString().length == 1 ? '0'+date.getMinutes() : date.getMinutes(),
  ampm = date.getHours() >= 12 ? 'pm' : 'am',
  hours = date.getHours().toString().length == 1 ? '0'+date.getHours() : date.getHours(),
  months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
  days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
  if(hours > 12) {hours = hours - 12;}
  return days[date.getDay()]+' '+months[date.getMonth()]+' '+date.getDate()+' '+date.getFullYear()+' '+hours+':'+minutes+ampm;
}

export function timeToStringSimple(time) {
  const date = new Date(time)
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  return months[date.getMonth()]+' '+date.getDate()+', '+date.getFullYear();
}

export function timeToStringTimeonly(time) {
  const date = new Date(time)
  let minutes = date.getMinutes().toString().length == 1 ? '0'+date.getMinutes() : date.getMinutes(),
  ampm = date.getHours() >= 12 ? 'pm' : 'am',
  hours = date.getHours().toString().length == 1 ? '0'+date.getHours() : date.getHours();
  if(hours > 12) {hours = hours - 12;}
  return hours+':'+minutes+ampm;
}
