import React from 'react'
import ReactSlider from 'react-slider'

export default function OsSlider ({ unit, value, onChange }) {
  return (
    <div style={styles.stepContainer}>
      <div style={styles.metricIncrement}>
        <ReactSlider
          className="horizontal-slider"
          style={{flex: 1}}
          thumbClassName="example-thumb"
          trackClassName="example-track"
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          step={1}
          value={value}
          onChange={onChange}
          max={10}
          min={0}
        />
      </div>
      <div style={styles.metricCounter}>
        <div style={{fontSize: 18, textAlign: 'center', color: '#6d6d6d'}}>{value}</div>
        <div style={{fontSize: 12, textAlign: 'center', color: '#9e9e9e'}}>{unit}</div>
      </div>
    </div>
  )
}

const styles = {
  stepContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    paddingLeft: 10,
  },
  metricIncrement: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft:20,
    paddingRight:20,
  },
  metricCounter: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft:20,
    paddingRight:20,
  },
}
