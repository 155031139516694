import React from "react";
import { connect } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Error404 from "./components/Error404";
import DashboardPage from "./components/DashboardPage";
import ClientPage from "./components/ClientPage";
import ClientDataCollection from "./components/ClientDataCollection";
//import logo from './logo.svg';
import "./App.css";
import { apiCheckToken } from "./utils/api";
import { font_type } from "./utils/helpers";
import { UPDATE_USER } from "./actions";

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userData) => dispatch({ type: UPDATE_USER, userData }),
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<DashboardPage />} />
      <Route path="client" element={<ClientPage />} exact={false} />
      <Route
        path="datacollection"
        element={<ClientDataCollection />}
        exact={false}
      />
      <Route path="*" element={<Error404 />} />
    </>
  )
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
    };
  }

  componentDidMount() {
    //setCookie('fc3201219a9a8f60f4b386fb0d638e12', '458ff96c046f287e9350a082f78b5416', { expires: 1 }); //for testing.

    //const joomlaCookie = this.getJoomlaCookie();
    //console.log('Joomla Cookie: ', joomlaCookie);

    if (!this.state.loggedIn || !this.props.user.id) {
      //Refresh the token, we haven't logged them in yet.

      const params = new URLSearchParams(window.location.search);
      const token = params.get("login"); //Get the token from the URL params
      //const token = "d8add76045b78429cdfe8dd5ac4d734a"; //Test login
      if (token) {
        apiCheckToken(token)
          .then((tokenResult) => {
            if (tokenResult.error == 1) {
              console.log("User Token Check ERROR: ", tokenResult.message);
              //let userObject = { id: 0, session: '' }
              //localStorage.setItem('KidsLife:data', JSON.stringify({userObject}));
            } else {
              console.log("User Token Check SUCCESS: ", tokenResult.data);
              this.props.updateUser(tokenResult.data); //Redux - Update the redux store for app state
              //let userObject = tokenResult.data;
              //localStorage.setItem('KidsLife:data', JSON.stringify({userObject})); //Local Storage - Update the user object for next app open
              this.setState({ loggedIn: true });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  getJoomlaCookie = () => {
    // Get the Joomla session cookie. The cookie name might be different, adjust accordingly.
    const regex = /[a-z0-9]{32}=/; // A regex pattern to match 32 alphanumeric characters followed by '='
    const matchedCookie = document.cookie
      .split("; ")
      .find((row) => regex.test(row));
    console.log("getJoomlaCookie: ", document.cookie);
    return matchedCookie ? matchedCookie.split("=")[1] : null;
  };

  openMenu = () => {
    //this.props.navigation.toggleDrawer();
  };

  render() {
    if (!this.state.loggedIn) {
      return (
        <div className="App">
          <header className="App-header">
            <p>
              You are not logged in. Please return to your{" "}
              <a href="https://api.kidslifesolutions.com">dashboard</a> and
              access the data collection screen again.
            </p>
          </header>
        </div>
      );
    }
    return (
      <div className="App">
        <div style={styles.header}>
          <div style={styles.left}></div>
          <div style={styles.body}>
            <span style={styles.text_title}></span>
          </div>
          <div style={styles.right}>
            <a onClick={this.openMenu}></a>
          </div>
        </div>
        <RouterProvider router={router} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

const styles = {
  header: {
    backgroundColor: "rgba(0,0,0,0.3)",
    borderBottomWidth: 0,
    paddingTop: 1,
    paddingBottom: 1,
    elevation: 0,
    paddingLeft: 1,
    paddingRight: 1,
  },
  left: {
    flex: 0.5,
    backgroundColor: "rgba(0,0,0,0)",
  },
  body: {
    flex: 3,
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0)",
  },
  text_title: {
    color: "white",
    fontSize: 17,
    marginTop: 1,
    alignSelf: "center",
    fontFamily: font_type.FontBold,
  },
  right: {
    flex: 0.5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
};
